import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Box } from '@material-ui/core';

import styles from './styles';
import globalStyles from '../../utils/globalStyles';
import CustomButton from '../CustomButton/customButton';
import Download from '../Download';
import { KNOW_MORE } from '../../utils/constants';
import { addProtocol } from '../../utils/format';
import { switchAdherentPath } from '../../utils/services/format';

const UnorderedList = ({ title, src, isSubcategory, isFirst, section, findSearchResult, id }) => {
  const myRef = useRef(null)
  const classes = styles({ isSubcategory, findSearchResult });
  const globalClasses = globalStyles();

  useEffect(() => {
    if (findSearchResult) {
      myRef.current.scrollIntoView(true);
    }
  }, [findSearchResult]);

  return (
    <Grid container className={classes.wrapper}>
      <Grid item container xs={12} md={9} className={globalClasses.verticalCenter}>
        <Box m={1}>
          <div className={classes.bullet}></div>
        </Box>
        <div className={classes.listItem}>
          <div className={classes.listItemAnchor} ref={myRef}></div>
          {title}
        </div>
      </Grid>
      {isSubcategory && (
        <Grid item xs={12} md={3} className={clsx(classes.buttonWrapper, globalClasses.verticalCenter, globalClasses.horizontalCenter)}>
          {isFirst
            ? <Download file={src} />
            : <CustomButton text={KNOW_MORE} link={addProtocol(`${process.env.GATSBY_ADHERENT_URL}/${switchAdherentPath(section)}?id=${id}`, "https")} isOutsideLink />
          }
        </Grid>
      )}
    </Grid>
  )
};

export default UnorderedList

UnorderedList.propTypes = {
  title: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  src: PropTypes.string,
  isSubcategory: PropTypes.bool,
  section: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

UnorderedList.defaultProps = {
  isSubcategory: false,
  isFirst: false
};
