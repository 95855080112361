import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  paper: {
    position: 'inherit', // Removes unwanted top line
  },
  accordionHeader: {
    background: theme.palette.primary.mainBGColor,
    borderRadius: 40,
    padding: 'clamp(5px, 1vw, 22px) 28px',
    width: '75%',
    margin: '0 auto',
    '& .MuiAccordionSummary-content': {
      display: 'block',
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  accordionHeaderTextContainer: {
    '@media(max-width: 600px)': {
      flexDirection: 'column'
    },
  },
  listWrapper: {
    boxShadow: '0px 3px 6px #00000029',
    padding: 'clamp(30px, 2vw, 55px)',
    flexDirection: 'column'
  },
  subcategoryWrapper: {
    paddingLeft: '2rem',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 'clamp(2rem, 20vw, 5rem)'
    }
  },
  categoryTitle: {
    textAlign: "center"
  }
}));
