import React from 'react'
import PropTypes from 'prop-types';
import Icon from '../Icons/Icon';

import {
  faArrowAltCircleDown,
} from '@fortawesome/free-solid-svg-icons'


const Download = ({ file }) => {
  return <Icon icon={faArrowAltCircleDown} variant="black" size="2x" link={file} download />
}

export default Download

Download.propTypes = {
  file: PropTypes.string.isRequired
};
