import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  verticalCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  horizontalCenter: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
