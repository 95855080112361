import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'clamp(10px, 1.5vw, 20px) 0',
    '@media (max-width:450px)': {
      flexDirection: 'column',
    },
  },
  listItem: {
    fontSize: 'clamp(1rem, 2vw, 20px)',
    color: theme.palette.primary.main,
    backgroundColor: ({findSearchResult}) => findSearchResult && 'yellow',
    listStyle: 'none',
    fontWeight: 'bold',
    '&::marker': {
      display: 'none'
    },
    width: '85%',
    position: 'relative'
  },
  listItemAnchor: {
    top: '-150px',
    position: 'absolute'
  },
  bullet: {
    background: ({ isSubcategory }) => isSubcategory ? theme.palette.primary.mainBGColor : theme.palette.primary.main,
    borderRadius: '50%',
    height: 'clamp(8px, 1.2vw, 12px)',
    width: 'clamp(8px, 1.2vw, 12px)',
  },
  buttonWrapper: {
    minWidth: 115,
    [theme.breakpoints.down('md')]: {
      margin: '1rem 0',
      // justifyContent: 'flex-start !important', // si finalement on veut aligner les boutons à gauche en responsive
    },
  }
}));
