import React from 'react'
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Grid } from '@material-ui/core';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import Icon from '../Icons/Icon';
import UnorderedList from '../UnorderedList';
import DocumentsIcons from '../DocumentsIcons';

import { sortArrayOfObjectsOnFieldName } from '../../utils/services/format';
import styles from './styles';
import { REGLEMENTATIONS } from '../../utils/constants';

const CustomAccordion = ({ title, items, index, withIcon, section, expanded }) => {
  const classes = styles();
  const { searchSubCategory } = useSelector(state => ({
    searchSubCategory: state.getIn(['root', 'searchSubCategory']),
  }));

  const sortedSubCategories = sortArrayOfObjectsOnFieldName(items, 'priority');
  return (
    <Box my={3}>
      <Accordion
        elevation={0}
        classes={{ root: classes.paper }}
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={() => !expanded}

      >
        <AccordionSummary
          expandIcon={<Icon icon={faAngleDown} variant="white" size="2x" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ root: classes.accordionHeader }}
        >
          <Grid container alignItems="center" justify="center" className={classes.accordionHeaderTextContainer}>
            <Box m={2}>
              {withIcon && <DocumentsIcons section={section} index={index} />}
            </Box>
            <span>
              <Typography variant="h2" className={classes.categoryTitle}>{title}</Typography>
            </span>
          </Grid>
        </AccordionSummary>

        <AccordionDetails classes={{ root: classes.listWrapper }}>
          {sortedSubCategories?.map((item, key) => {
            const sortedDocuments = sortArrayOfObjectsOnFieldName(item?.document, 'priority');
            return (
              <Box key={key}>
                <UnorderedList title={item.title} section={section} />
                {sortedDocuments?.map((document, idx) => {
                  const findSearchResult = document?.title === searchSubCategory?.resultTitle
                  return (
                    <Box key={idx} className={classes.subcategoryWrapper}>
                      <UnorderedList
                        title={document?.title}
                        src={document?.file?.file?.url}
                        isSubcategory
                        isFirst={(index === 0 && key === 0 && idx === 0) || section === REGLEMENTATIONS}
                        section={section}
                        findSearchResult={findSearchResult}
                        id={document?.contentful_id}
                      />
                    </Box>
                  )
                })}
              </Box>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
export default CustomAccordion

CustomAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    document: PropTypes.arrayOf(PropTypes.shape({
      contentful_id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      priority: PropTypes.number.isRequired,
      file: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired
        })
      })
    })).isRequired
  })).isRequired,
  index: PropTypes.number.isRequired,
  withIcon: PropTypes.bool,
  section: PropTypes.string.isRequired,
};

CustomAccordion.defaultProps = {
  withIcon: false
};
