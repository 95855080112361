import React from 'react'
import { graphql } from 'gatsby'
import { Grid } from '@material-ui/core'
import { Helmet } from 'react-helmet';

import Layout from '../components/layout'
import CustomAccordion from '../components/CustomAccordion'
import Hero from '../components/Hero'
import Subtitle from '../components/Subtitle'
import { renderDocumentPagesStaticData } from '../utils/services/format'
import { REGLEMENTATIONS } from '../utils/constants'
import { useSelector } from 'react-redux';

const DocumentsPages = ({ data }) => {
  const categories = data.categories.nodes;
  const section = categories.find(item => item.section).section;


  const { searchSubCategory } = useSelector(state => ({
    searchSubCategory: state.getIn(['root', 'searchSubCategory']),
  }));

  const page = renderDocumentPagesStaticData(data, section);

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{page.title}</title>
      </Helmet>

      <Hero title={page.title} alt={page.title} image={page.image} />
      <Subtitle text={page.intro} />
      <Grid container justify="center">
        <Grid item xs={11} md={9}>
          {categories.map((category, key) => {
            const openSearchResult = category.subcategories?.find((sub) => sub?.title === searchSubCategory?.subCategory)
            const documentExists = category.subcategories && category.subcategories?.find(sub => sub?.document);
            return documentExists && (
              <CustomAccordion
                key={key}
                title={category.title}
                items={category.subcategories}
                withIcon={section === REGLEMENTATIONS ? false : true}
                index={key}
                section={section}
                expanded={openSearchResult}
              />
            )
          })}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default DocumentsPages

export const query = graphql`
  query($slug: String!) {
    categories: allContentfulCategories (
      filter: { section: { eq: $slug } }
      sort: { fields: priority, order: ASC }
    ) {
      nodes {
        title
        priority
        section
        subcategories: sous_categorie {
          ... on ContentfulSousCategorie {
            title
            priority
            document {
              ... on ContentfulDocument {
                contentful_id
                title
                priority
                file {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }

    texts: markdownRemark(fileAbsolutePath: { regex: "/sections.md/" }) {
      frontmatter {
        legalTitle
        legalIntro
        legalCoverImage {
          childImageSharp {
            gatsbyImageData
          }
        }

        litigationsTitle
        litigationsIntro
        litigationsImage {
          childImageSharp {
            gatsbyImageData
          }
        }

        reglementationsTitle
        reglementationsIntro
        reglementationsImage {
          childImageSharp {
            gatsbyImageData
          }
        }

        traceabilityTitle
        traceabilityIntro
        traceabilityImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
