import React from 'react';
import PropTypes from 'prop-types';

import {
  faSyringe,
  faNotesMedical,
  faExclamationCircle,
  faBriefcaseMedical,
  faDumbbell,
  faUsers,
  faLink,
  faClinicMedical,
  faUserNurse,
  faUserGraduate
} from '@fortawesome/free-solid-svg-icons';

import {
  faFileAlt,
  faFolderOpen,
  faBuilding
} from '@fortawesome/free-regular-svg-icons'

import Icon from '../Icons/Icon';
import { JURIDIQUE, CONTENTIEUX, TRACABILITE } from '../../utils/constants';

const DocumentsIcons = ({ section, index }) => {
  if (section === JURIDIQUE) {
    switch (index) {
      case 0:
        return <Icon icon={faBriefcaseMedical} variant="white" />
      case 1:
        return <Icon icon={faDumbbell} variant="white" />
      case 2:
        return <Icon icon={faUsers} variant="white" />
      case 3:
        return <Icon icon={faLink} variant="white" />
      case 4:
        return <Icon icon={faClinicMedical} variant="white" />
      case 5:
        return <Icon icon={faUserNurse} variant="white" />
      case 6:
        return <Icon icon={faUserGraduate} variant="white" />
      default:
        return <Icon icon={faExclamationCircle} variant="white" />
    }
  } else if (section === CONTENTIEUX) {
    return <Icon icon={faExclamationCircle} variant="white" />
  } else if (section === TRACABILITE) {
    switch (index) {
      case 0:
        return <Icon icon={faFolderOpen} variant="white" />
      case 1:
        return <Icon icon={faBuilding} variant="white" />
      case 2:
        return <Icon icon={faSyringe} variant="white" />
      case 3:
        return <Icon icon={faFileAlt} variant="white" />
      case 4:
        return <Icon icon={faBuilding} variant="white" />
      case 5:
        return <Icon icon={faNotesMedical} variant="white" />
      default:
        return <Icon icon={faExclamationCircle} variant="white" />
    }
  }
}

export default DocumentsIcons;

DocumentsIcons.propTypes = {
  section: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
};
